<template>
  <v-container grid-list-md>
    <v-row>
      <v-col cols="12" md="10" lg="8" offset-md="1" offset-lg="2">
        <v-card v-if="submitted">
          <v-card-title>Probasco Guest Room Availability Request Submitted</v-card-title>
          <v-card-text v-html="confirmationText"></v-card-text>
        </v-card>
        <v-card v-else-if="submitting">
          <v-card-title>Probasco Guest Room Availability Request</v-card-title>
          <v-card-text>Your request is being saved. This should only take a few seconds...</v-card-text>
        </v-card>
        <v-card v-else>
          <portal-form
            :info-card="revision.infoCard"
            :info-card-text="revision.infoCardText"
            :section-type="revision.sectionType"
            :sections="revision.sections"
            submit-label="Pay and Submit Reservation"
            @updatedField="updateField"
            @updatedData="updateData"
            @submit="saveSubmission"></portal-form>
          <payment-popup ref="paymentDialog" :total="total" :items="items" :show-activator="false" department="Admissions" @cancel="handleCancel" @success="submit"></payment-popup>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { computed, onMounted, reactive, ref } from '@vue/composition-api'
export default {
  components: {
    PortalForm: () => import('@/components/forms/Form'),
    PaymentPopup: () => import('@/components/core/PaymentPopup')
  },
  setup (props, { root }) {
    const revision = reactive({
      infoCard: false,
      infoCardText: '',
      sectionType: 'card',
      sections: []
    })
    const confText = ref('')
    const initFormData = reactive({})
    const dateField = ref('')
    const nightField = ref('')
    const blockedDates = ref([])

    const formId = '620a770dcd2d376aec336e22'
    const formName = ref('')
    const revisionId = ref('')
    const calendarId = 'covenant.edu_km08oi0mh2grn8edpc2kopvkps@group.calendar.google.com'

    const submissionId = ref('')

    onMounted(async () => {
      // Load the dates from the calendar to block out
      const query = { calendarId, futureOnly: true, maxResults: 100 }
      const { data: { items } } = await root.$feathers.service('system/calendar').find({ query })
      for (const { start, end } of items) {
        if (start.date === end.date) {
          blockedDates.value.push(start.date)
        } else {
          const startDate = new Date(start.date)
          const endDate = new Date(end.date)
          while (endDate.toISOString().substring(0, 10) > startDate.toISOString().substring(0, 10)) {
            blockedDates.value.push(startDate.toISOString().substring(0, 10))
            startDate.setDate(startDate.getDate() + 1)
          }
        }
      }
      // Load the form
      const form = await root.$feathers.service('forms/base').get(formId)
      if (!('revisions' in form) || !('active' in form.revisions) || form.revisions.active == null) {
        root.$store.dispatch('main/snackbar', { active: true, type: 'error', timeout: 6000, text: 'The Probasco Request form is currently unavailable' })
        root.$router.push('/accommodations/request')
        return
      }
      formName.value = form.name
      // Load the confirmation text (not stored in the revision)
      if ('confText' in form) confText.value = form.confText
      revisionId.value = form.revisions.active

      // Load the form revision
      const data = await root.$feathers.service('forms/revision').get(form.revisions.active)
      // Find the date picker (where they choose their start date) and block out the dates that are already taken
      for (let i = 0; i < data.sections.length; i++) {
        for (let j = 0; j < data.sections[i].inputs.length; j++) {
          if (data.sections[i].inputs[j].input === 'datePicker') {
            const temp = data.sections[i].inputs[j]
            dateField.value = temp.label
            let dt = new Date()
            dt.setDate(dt.getDate() + 3)
            temp.minDate = dt.toISOString().substring(0, 10)
            dt.setDate(dt.getDate() - 3)
            dt.setMonth(dt.getMonth() + 4)
            temp.maxDate = dt.toISOString().substring(0, 10)
            temp.blockedDates = blockedDates.value
            console.log(temp)
            data.sections[i].inputs.splice(j, 1, temp)

            if ((j + 1) in data.sections[i].inputs && data.sections[i].inputs[j + 1].input === 'number') {
              nightField.value = data.sections[i].inputs[j + 1].label
            }
            break
          }
        }
      }
      for (let l in data) {
        revision[l] = data[l]
      }
    })

    const formData = ref({})
    const lastDateChange = ref('')

    function updateField ({ field, value, index, action }) {
      if (field === dateField.value && value !== '' && value !== lastDateChange.value) {
        console.log(field, value)
        // Calculate how many would be the maximum number of nights they can choose and update the night field with the new max
        let dt = new Date(value)
        dt.setDate(dt.getDate() + 1)
        let maxReached = false
        let max = 1
        for (let i = 1; i < 5; i++) {
          for (const date of blockedDates.value) {
            if (date === dt.toISOString().substring(0, 10)) {
              maxReached = true
              break
            }
          }
          if (maxReached) {
            max = i
            console.log('New max: ' + max)
            break
          }
          dt.setDate(dt.getDate() + 1)
        }
        if (!maxReached) {
          max = 5
          console.log('New max: ' + max)
        }
        lastDateChange.value = value
        for (let i = 0; i < revision.sections.length; i++) {
          for (let j = 0; j < revision.sections[i].inputs.length; j++) {
            if (revision.sections[i].inputs[j].label === nightField.value) {
              const temp = { ...revision.sections[i].inputs[j] }
              temp.max = max
              if (nightField.value in formData.value && formData.value[nightField.value] > max) {
                formData.value[nightField.value] = max
                temp.value = max
              }
              revision.sections[i].inputs.splice(j, 1, temp)
              // const section = Object.assign({}, revision.sections[i])
              // section.inputs.splice(j, 1, temp)
              // revision.sections.splice(i, 1, section)
            }
          }
        }
      }
    }

    function updateData (data) {
      formData.value = data
      if ('What is your connection to Covenant?' in formData.value) {
        discountedPrice.value = false
        for (const type of formData.value['What is your connection to Covenant?']) {
          if (type === 'Covenant Alum' || type === 'Prospective Student and/or Family') discountedPrice.value = true
        }
      }
      if (nightField.value in data && data[nightField.value] != null && data[nightField.value] !== '' && data[nightField.value] > 0) {
        numberOfNights.value = data[nightField.value]
      }
    }

    const paymentDialog = ref(null)
    const discountedPrice = ref(false)
    const nightPrice = computed(() => {
      return (discountedPrice.value ? 69 : 89)
    })
    const numberOfNights = ref(0)
    const total = computed(() => {
      return nightPrice.value * numberOfNights.value
    })
    const items = computed(() => {
      const arr = [{
        name: 'Probasco Guest Room Night' + (numberOfNights.value > 1 ? 's' : ''),
        unit_amount: {
          currency_code: 'USD',
          value: nightPrice.value
        },
        quantity: numberOfNights.value
      }]
      return arr
    })

    async function saveSubmission () {
      const dataArr = []
      for (const field in formData.value) {
        const value = formData.value[field]
        dataArr.push({ field, value })
      }
      let { _id } = await root.$feathers.service('forms/submission').create({
        name: formData.value['First Name'] + ' ' + formData.value['Last Name'],
        form: { // The details of the form for which this submission was made; the name is here in addition to the Forms-Base to make it simpler to load for the form submission history
          name: formName.value,
          base: formId,
          revision: revisionId.value
        },
        status: 'Unsubmitted',
        submittedDate: new Date(),
        dataArr
      })
      submissionId.value = _id
      paymentDialog.value.activate()
    }

    function handleCancel () {
      // console.log('payment cancelled')
    }

    const confirmationText = computed(() => {
      let str = confText.value
      for (const field in formData.value) {
        const value = formData.value[field]
        str.replace(new RegExp('<code>' + field + '</code>', 'g'), value)
      }
      return str
    })

    const submitting = ref(false)
    const submitted = ref(false)
    async function submit ({ logId, paypalId }) {
      // Payment is complete; save the request
      submitting.value = true
      // Update the submission to the forms/submission service
      //   Note: This will automatically send confirmation emails to the submitter and any staff who need notificataion
      await root.$feathers.service('forms/submission').patch(submissionId.value, {
        status: 'Submitted',
        payment: {
          paypalId,
          logId
        }
      })
      // Show the confirmation message
      submitting.value = false
      submitted.value = true
    }

    return {
      revision,
      confText,
      confirmationText,
      initFormData,
      dateField,
      nightField,
      blockedDates,
      formData,
      lastDateChange,
      updateField,
      updateData,
      paymentDialog,
      discountedPrice,
      nightPrice,
      numberOfNights,
      total,
      items,
      saveSubmission,
      handleCancel,
      submit,
      submitting,
      submitted
    }
  }
}
</script>
